import React from 'react';
import './assets/css/App.css';

import Router from './Router';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import {primaryColor} from 'assets/jss/material-dashboard-react.js'


function App() {
    var theme = createMuiTheme({
        palette: {
            primary: {
                main: primaryColor[1]
            },
            secondary: {
                main: '#1D6069'
            }
        },
    })
    return (
        <ThemeProvider theme={theme}>
            <Router />
        </ThemeProvider>
    );
}

export default App;