import React from 'react';
import ButtonKit from "components/CustomButtons/Button.js";
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Alert from './Alert'
import {
    useMediaQuery,
    Typography,
    Dialog,
    DialogActions,
    Slide,
    Button
} from '@material-ui/core/';

import axios from 'axios'
import Global from '../Global'
import Context from 'components/Context'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © eLocal'}
            {' '}
            {"1991 - " + new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(() => ({
    image: {
        width: '100%',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    scrollPaper: {
        maxHeight: "100%"
    },
}));

export default function SignInSide(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false)
    const [message, setMessage] = React.useState("")
    const [severity, setSeverity] = React.useState("")
    const context = React.useContext(Context)
    const fullScreen = useMediaQuery("(max-width:900px)");
    const [nickname, setNickname] = React.useState("")
    const [email, setEMail] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [passwordTwo, setPasswordTwo] = React.useState("")
    const [nombre, setNombre] = React.useState("")
    const [apellidos, setApellidos] = React.useState("")
    const [steps, setSteps] = React.useState(0)
    const signIn = () => {
        if (nickname === "" || password === "") {
            setSeverity("warning")
            setMessage("Rellene todos los campos obligatorios")
            setOpen(true)
        } else {
            axios.post(Global.url + "foro/signin", { nickname, password }).then(res => {
                if (res.data.status === "error") {
                    setSeverity("error")
                    setMessage(res.data.message)
                    setOpen(true)
                } else {
                    sessionStorage.setItem("UserToken", "H " + res.data.token)
                    context.private("H " + res.data.token)
                    props.setOpenDialog(false)
                }
            })
        }
    }
    const signUp = () => {
        if (nombre && apellidos && /^[A-Za-z0-9]+$/.test(nickname) && !Global.validateEmail(email).status && !Global.validatePass(password, passwordTwo).status) {
            axios.post(`${Global.url}foro/signup`,{email,password,nombre,apellidos,nickname}).then(res => {
                if (res.data.status === "success") {
                    sessionStorage.setItem("UserToken", "H " + res.data.token)
                    context.private("H " + res.data.token)
                    props.setOpenDialog(false)
                }else{
                    if(res.data.err !== undefined){
                        const msg = []
                        for(var err in res.data.err){
                            msg.push(res.data.err[err].properties.message)
                        }
                        setMessage(msg.join(", ")+".")
                    }else{
                        setMessage(res.data.message)
                    }
                    setSeverity("error")
                    setOpen(true)
                }
            })
        } else {
            setSeverity("warning")
            setMessage("Rellene todos los campos, sin errores.")
            setOpen(true)
        }
    }
    const keyEnterPassword = (e) => {
        if (e.key === "Enter") {
            if (steps === 0) {
                signIn()
            } else if (steps === 1) {
                signUp()
            }
        }
    }
    React.useEffect(() => {
        if (steps === 1) {
            if (!Global.validateEmail(nickname).status) {
                setEMail(nickname)
                setNickname("")
            }
        } if (steps === 0 && nickname === "") {
            setNickname(email)
        }
    }, [steps, nickname, email])
    return (
        <Dialog
            open={props.openDialog}
            TransitionComponent={Transition}
            keepMounted
            disableBackdropClick={props.disableBackdropClick}
            disableEscapeKeyDown={props.disableEscapeKeyDown}
            onClose={() => props.setOpenDialog(false)}
            classes={{ paperScrollPaper: classes.scrollPaper }}
            fullScreen={fullScreen}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogActions>
                <Button onClick={() => {
                    if (props.disableBackdropClick) window.location.href = "/"
                    else props.setOpenDialog(false)
                }} color="primary">
                    X
                </Button>
            </DialogActions>


            <Grid container component="main" className={classes.root} justify="center" alignItems="center">

                <CssBaseline />
                <Grid item xs={11} sm={8} md={8}>
                    <div className={classes.paper}>
                        {steps === 0 &&
                            <form className={classes.form} noValidate>
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    required
                                    fullWidth
                                    value={nickname}
                                    onChange={(v) => setNickname(v.target.value.replace(/\s/g, ""))}
                                    label="Usuario o Email"
                                    name="current-email"
                                />
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Contraseña"
                                    value={password}
                                    onChange={(v) => setPassword(v.target.value)}
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    onKeyPress={keyEnterPassword}
                                />
                                <ButtonKit
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={signIn}
                                >
                                    Iniciar sesión
                            </ButtonKit>
                                <ButtonKit
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={() => setSteps(1)}
                                >
                                    Registrarse
                            </ButtonKit>
                            </form>
                        }
                        {steps === 1 &&
                            <form className={classes.form} noValidate>
                                <Grid container component="main" className={classes.root} justify="center" alignItems="center">
                                    <Grid item xs={12} sm={4} md={4}>
                                        <TextField
                                            variant="standard"
                                            margin="normal"
                                            required
                                            fullWidth
                                            value={nombre}
                                            onChange={(v) => setNombre(v.target.value)}
                                            label="Nombre"
                                            name="given-name"
                                            autoComplete="given-name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8}>
                                        <TextField
                                            variant="standard"
                                            margin="normal"
                                            required
                                            fullWidth
                                            value={apellidos}
                                            onChange={(v) => setApellidos(v.target.value)}
                                            label="Apellidos"
                                            name="family-name"
                                            autoComplete="family-name"
                                        />
                                    </Grid>
                                </Grid>
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    required
                                    fullWidth
                                    value={nickname}
                                    onChange={(v) => setNickname(v.target.value.replace(/\s/g, ""))}
                                    error={nickname.length > 0 && !/^[A-Za-z0-9]+$/.test(nickname)}
                                    helperText={nickname.length > 0 && (/^[A-Za-z0-9]+$/.test(nickname) ? "" : "No puede contener caracteres especiales")}
                                    label="Usuario"
                                    name="nickname"
                                    autoComplete="nickname"
                                />
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    required
                                    fullWidth
                                    value={email}
                                    onChange={(v) => setEMail(v.target.value.replace(/\s/g, ""))}
                                    error={email.length > 0 ? Global.validateEmail(email).status : false}
                                    helperText={email.length > 0 && Global.validateEmail(email).message}
                                    label="Email"
                                    name="email"
                                    autoComplete="email"
                                />
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Nueva contraseña"
                                    value={password}
                                    error={(password.length > 0 && passwordTwo.length > 0) && Global.validatePass(password, passwordTwo).status}
                                    onChange={(v) => setPassword(v.target.value)}
                                    type="password"
                                    name="new-password"
                                    id="password"
                                    autoComplete="new-password"
                                />
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Repita su contraseña"
                                    value={passwordTwo}
                                    error={(password.length > 0 && passwordTwo.length > 0) && Global.validatePass(password, passwordTwo).status}
                                    helperText={(password.length > 0 && passwordTwo.length > 0) && Global.validatePass(password, passwordTwo).message}
                                    onChange={(v) => setPasswordTwo(v.target.value)}
                                    type="password"
                                    name="repeat-password"
                                    autoComplete="new-password"
                                    onKeyPress={keyEnterPassword}
                                />
                                <ButtonKit
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={signUp}
                                >
                                    Registrarse
                            </ButtonKit>
                                <ButtonKit
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={() => setSteps(0)}
                                >
                                    Iniciar sesión
                            </ButtonKit>
                            </form>
                        }
                        <Box mt={5}>
                            <Copyright />
                        </Box>

                    </div>

                </Grid>
                <Alert open={open} setOpen={(e) => setOpen(e)} message={message} severity={severity}></Alert>

            </Grid>
        </Dialog>
    );
}