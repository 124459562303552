/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, useHistory, Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import logoCorona from "assets/img/elocal.png";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const history = useHistory()
  const classes = useStyles();
  const [links, setLink] = React.useState()
  const setLinks = () => setLink(<List className={classes.list}>
    {routes.map((prop, key) => {
      var activePro = " ";
      var listItemClasses;
      listItemClasses = classNames({
        [" " + classes[color]]: activeRoute(prop.path)
      });

      const whiteFontClasses = classNames({
        [" " + classes.whiteFont]: activeRoute(prop.path)
      });
      return (
        <NavLink
          exact={prop.path === "/" ?true:false}
          to={prop.path}
          className={activePro + classes.item}
          activeClassName="active"
          key={key}
        >
          <ListItem button className={classes.itemLink + listItemClasses}>
            {typeof prop.icon === "string" ? (
              <img
                src={prop.icon}
                className={classNames(classes.itemIcon, whiteFontClasses)}
              />
            ) : (
                <prop.icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                />
              )}
            <ListItemText
              primary={props.rtlActive ? prop.rtlName : prop.name}
              className={classNames(classes.itemText, whiteFontClasses, {
                [classes.itemTextRTL]: props.rtlActive
              })}
              disableTypography={true}
            />
          </ListItem>
        </NavLink>
      );
    })}
  </List>)
  React.useEffect(()=>{setLinks()},[])
  history.listen(()=>{
    setLinks()
  })
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    if(routeName === "/") return window.location.pathname === routeName ? true : false;
    return `${window.location.pathname}/`.indexOf(`${routeName}/`) > -1 ? true : false;
  }
  const { color, logoText, routes } = props;
  var brand = (
    <div className={classes.logo}>
      <a
        href="https://www.elocal.es"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
        style={{display:"flex", alignItems:"center"}}
        target="_blank"
      >
          <img src={logoCorona} alt="logo" className={`${classes.img} ${classes.logoImage}`} />
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            {links}
            <Link to="aviso-legal" style={{width:"100%"}}>
          <ListItem button className={classes.itemLink + classNames({
        [" " + classes[color]]: false
      })}>
            <ListItemText
              primary={"Aviso legal"}
              className={classNames(classes.itemText, classNames({
                [" " + classes.whiteFont]: false
              }), {
                [classes.itemTextRTL]: props.rtlActive
              })}
              disableTypography={true}
            />
          </ListItem></Link>
          </div>
            <div
              className={classes.background}
              style={{ backgroundColor: "blue" }}
            />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}
          <Link to="/aviso-legal" style={{position:"absolute", bottom:"10px", width:"100%"}}>
          <ListItem button className={classes.itemLink + classNames({
        [" " + classes[color]]: false
      })} style={{textAlign: "center"}}>
            <ListItemText
              primary={"Aviso legal"}
              className={classNames(classes.itemText, classNames({
                [" " + classes.whiteFont]: false
              }), {
                [classes.itemTextRTL]: props.rtlActive
              })}
              disableTypography={true}
            />
          </ListItem></Link>
          </div>
          <div
              className={classes.background}
              style={{ backgroundColor: "blue" }}
            />
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};
