import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import moment from 'moment'
import {
  primaryColor,
  whiteColor,
} from "assets/jss/material-dashboard-react.js";
import {Link} from 'react-router-dom'
import Global from 'Global'

const useStyles = makeStyles(styles);
const hover = makeStyles(()=>({
  hover:{
    "&:hover": {
      backgroundColor: primaryColor[1],
      color: whiteColor,
    },
  },
  labels: {
    width: 99,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    textAlign:"center",
    marginTop: 2,
  }
}));

export default function CustomTable(props) {
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor } = props;
  const labels = Global.labels.concat(Global.labelsAdmin)
  const classess = hover();
  const theme = useTheme();
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((props, key) => {
            return (
              <TableRow key={key} className={[classes.tableBodyRow,classess.hover]} component={Link} to={props.path}>
                    <TableCell className={classes.tableCell} style={{color:"inherit"}}>
                      {moment(props["fecha"]).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell className={classes.tableCell} style={{color:"inherit"}}>
                      {props["titulo"]}
                    </TableCell>
                    <TableCell className={classes.tableCell} style={{color:"inherit"}}>
                      {props["tipo"].map((label,i) => (
                        <div
                          key={i}
                          className={classess.labels}
                          style={{
                            backgroundColor: labels.find(e=>e.name === label.name).color,
                            color: theme.palette.getContrastText(labels.find(e=>e.name === label.name).color),
                          }}
                        >
                          {label.name.replace("Tipo: ","").replace("Estado: ","").replace("Prioridad: ","")}
                        </div>
                      ))}
                    </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray"
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};
