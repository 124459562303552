import React, { useContext, useState } from "react";
// @material-ui/core
import { makeStyles, useTheme, withStyles, fade } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";

import { Redirect, Link } from 'react-router-dom'
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Context from 'components/Context'
import { CircularProgress, useMediaQuery, TextField } from '@material-ui/core';
import ButtonUI from '@material-ui/core/Button'
import {
  primaryColor,
  whiteColor,
} from "assets/jss/material-dashboard-react.js";
import Global from 'Global'
import moment from "moment"
import Login from 'components/Login'
import axios from 'axios'
import { Editor } from '@tinymce/tinymce-react';
import CardFooter from "components/Card/CardFooter.js";
import { Popper, ButtonBase } from "@material-ui/core/";
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import SettingsIcon from '@material-ui/icons/Settings';
import { Autocomplete } from '@material-ui/lab'
import Alert from 'components/Alert'

const useStyles = makeStyles(styles);
const hover = makeStyles((theme) => ({
  hover: {
    "&:hover": {
      backgroundColor: primaryColor[1],
      color: whiteColor,
    },
  },
  labels: {
    width: 110,
    flexShrink: 0,
    borderRadius: 3,
    fontSize: "15px",
    marginRight: 8,
    textAlign: "center",
    marginTop: 2,
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  root: {
    width: 221,
    fontSize: 13,
  },
  button: {
    fontSize: 13,
    width: '100%',
    textAlign: 'left',
    paddingBottom: 8,
    color: '#586069',
    fontWeight: 600,
    '&:hover,&:focus': {
      color: '#0366d6',
    },
    '& span': {
      width: '100%',
    },
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  tag: {
    marginTop: 3,
    height: 20,
    padding: '.15em 4px',
    fontWeight: 600,
    lineHeight: '15px',
    borderRadius: 2,
  },
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 3,
    width: 300,
    zIndex: 1,
    fontSize: 13,
    color: '#586069',
    backgroundColor: '#f6f8fa',
  },
  header: {
    borderBottom: '1px solid #e1e4e8',
    padding: '8px 10px',
    fontWeight: 600,
  },
  inputBase: {
    padding: 10,
    width: '100%',
    borderBottom: '1px solid #dfe2e5',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 14,
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: 13,
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2,
  },
  text: {
    flexGrow: 1,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
  }
}));
const WhiteTextField = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottomColor: 'rgb(0, 172, 193)', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: 'rgb(0, 172, 193)', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#4489d4', // Solid underline on focus
    },
  },
})(TextField);

export default function Dashboard(props) {
  const categoria = props.match.params.categoria
  const path = props.match.url
  const responseRef = React.createRef(null)
  const classes = useStyles();
  const context = useContext(Context)
  const [data, setData] = React.useState({});
  const [load, setLoad] = React.useState(false);
  const [coment, setComent] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false)
  const [response, setResponse] = React.useState(``)
  const [miEntrada, setMiEntrada] = React.useState(false)
  const [edit, setEdit] = React.useState(false)
  const labels = Global.labels.concat(Global.labelsAdmin)
  const classess = hover();
  const theme = useTheme();
  const width = useMediaQuery('(min-width:600px)') ? "row" : "column";
  const sendComent = () => {
    axios.post(`${Global.url}foro/savecoment`, { cuerpo: response + coment, fecha: moment(), idEntrada: data._id }, context.token).then(res => {
      if (res.data.status !== "error") {
        context.setEntradas(res.data.entradas)
        setComent("")
        setResponse("")
      }
    })
  }
  React.useEffect(() => {
    if (responseRef.current !== null) {
      const div = responseRef.current
      div.innerHTML = response
      div.scrollIntoView();
    }
  }, [response])
  React.useEffect(() => {
    var data = context.entradas.find(element => element.path === path)
    if (data) {
      var mia = context.misEntradas.find(element => element._id === data._id)
      setMiEntrada(Boolean(mia))
      setData(data)
      document.getElementById("article").innerHTML = data.cuerpo
    }
    setLoad(true)
  }, [categoria, context.entradas, path, context.load, context.user])
  const categoriaData = Global.sections.find(article => article.categoria = categoria)
  if (Object.keys(data).length > 0 || !load || !context.load) {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ maxWidth: "800px", margin: "0 auto" }}>
              <CardHeader color="rose" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <img src={categoriaData.asset} alt={categoria.title} height="40px" style={{ paddingRight: "10px" }}></img>
                <h3 style={{ color: "#777" }}>
                {categoria.title}
                </h3>
              </CardHeader>
              {edit
                ?
                <Editorr labels={data.tipo} titulo={data.titulo} categoria={categoria} cuerpo={data.cuerpo} data={data}></Editorr>
                :
                <>
                  <div style={{ display: "flex", flexDirection: width, justifyContent: "space-between", alignItems: "center", padding: "20px 30px 0" }}>
                    <div>
                      <h3 className={classes.cardTitle}>
                        {data.titulo}
                      </h3>
                      <p className={classes.cardCategory}>{moment(data.fecha).format("DD/MM/YYYY HH:mm")}{` - Autor: ${data.idCreador}${miEntrada ? ' (Tú)' : ''}`}</p>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                      {data.tipo && data.tipo.map((label, i) => (
                        <div
                          key={i}
                          className={classess.labels}
                          style={{
                            backgroundColor: labels.find(e => e.name === label.name).color,
                            color: theme.palette.getContrastText(labels.find(e => e.name === label.name).color),
                          }}
                        >
                          {label.name.replace("Tipo: ", "").replace("Estado: ", "").replace("Prioridad: ", "")}
                        </div>
                      ))}
                    </div>
                  </div>
                  {(context.user && context.user.rol >= 10 || miEntrada) &&
                    <div style={{ padding: "20px 30px 0" }}>
                      <Button color="white" onClick={() => setEdit(true)}><strong>EDITAR</strong></Button>
                    </div>
                  }
                  <CardBody>
                    <div id="article"></div>
                    {!load &&
                      <div style={{ width: "100%", display: "flex", justifyContent: "center", padding: "50px" }}>
                        <CircularProgress size={100} />
                      </div>
                    }
                  </CardBody>
                </>
              }
            </Card>
          </GridItem>
        </GridContainer>
        {!edit &&
          <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ maxWidth: "800px", margin: "20px auto" }}>
              <h4 style={{ paddingLeft: "20px", color: "#00acc1", fontWeight: "bold" }}>{"Comentarios"}</h4>
              <CardBody>
                {data.comentarios && data.comentarios.map((x, i) => (
                  <article style={{ borderTop: i === 0 ? "0.07rem solid #00acc1" : "0", borderBottom: "0.07rem solid #00acc1", paddingTop: "10px", paddingBottom: "10px" }} key={i}>
                    <div style={{ margin: "0.2rem 0", textAlign: "justify" }} dangerouslySetInnerHTML={{
                      __html: x.cuerpo
                    }}>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <p className={classes.cardCategory} style={{ fontSize: "85%" }}>{moment(x.fecha).format("DD/MM/YYYY HH:mm")} - {x.idCreador}</p>
                      <ButtonUI style={{
                        width: "fit-content",
                        float: "left",
                        textTransform: "none"
                      }}
                        color="primary"
                        onClick={() => setResponse(`
                      <blockquote 
                        style="
                        padding-left:10px;
                        border-left: 2px solid transparent;
                        -moz-border-image: -moz-linear-gradient(top, #3acfd5 0%, #3a4ed5 100%);
                        -webkit-border-image: -webkit-linear-gradient(top, #3acfd5 0%, #3a4ed5 100%);
                        -moz-border-image: 0 2px 0 0;
                        -webkit-border-image-width: 0 2px 0 0;
                        border-image: linear-gradient(to bottom, #3acfd5 0%, #3a4ed5 100%);
                        border-image-slice: 1;
                        border-image-width: 0 0 0 2px;
                        "><strong style="font-size:0.7rem;">En respuesta al comentario:</strong><br/>${x.cuerpo}</blockquote>
                      `)}>Responder</ButtonUI>
                    </div>
                  </article>
                ))}
                {data.comentarios && data.comentarios.length === 0 &&
                  <article style={{ borderTop: "0.07rem solid #00acc1", borderBottom: "0.07rem solid #00acc1", paddingTop: "10px", paddingBottom: "10px", textAlign: "center" }}>
                    <div>
                      {"Esta entrada no tiene comentarios"}
                    </div>
                    <div>
                      <p className={classes.cardCategory} style={{ fontSize: "85%", textAlign: "center" }}>{"¡Sé el primero en comentar!"}</p>
                    </div>
                  </article>
                }
                {context.load &&
                  <article style={{ paddingTop: "10px", paddingBottom: "10px", textAlign: "center" }}>
                    <div ref={responseRef} style={{ textAlign: "justify" }}></div>
                    {response !== '' &&
                      <ButtonUI style={{
                        width: "fit-content",
                        float: "left",
                        textTransform: "none"
                      }}
                        color="primary"
                        onClick={() => setResponse('')}
                      >X Eliminar mención</ButtonUI>
                    }
                    <div>
                      <WhiteTextField
                        fullWidth
                        color="secondary"
                        placeholder={(data.tipo && data.tipo.find(e => e.name === 'Estado: Cerrado') === undefined) ? "Escribir un comentario..." : "Entrada cerrada, no admite nuevos comentarios"}
                        multiline
                        classes={{
                          root: classess.textArea
                        }}
                        value={coment}
                        onChange={(e) => setComent(e.target.value)}
                        disabled={context.user === null || (data.tipo && data.tipo.find(e => e.name === 'Estado: Cerrado') !== undefined)}
                        rows={5}
                      />
                      {context.user === null &&
                        <Button style={{
                          position: "absolute",
                          width: "200px",
                          right: "calc(50% - 100px)",
                          bottom: "100px",
                        }}
                          color="primary"
                          onClick={() => setOpenDialog(true)}
                        >Inicia sesión o  Regístrate</Button>
                      }
                      <div>
                        <Button style={{
                          float: "right",
                          backgroundColor: "#4489d4"
                        }}
                          color="primary"
                          onClick={() => sendComent()}
                          disabled={context.user === null || !coment || (data.tipo && data.tipo.find(e => e.name === 'Estado: Cerrado') !== undefined)}
                        >{(data.tipo && data.tipo.find(e => e.name === 'Estado: Cerrado') === undefined) ? 'Publicar' : 'Cerrada'}</Button>
                      </div>
                    </div>
                  </article>
                }
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        }
        <Login setOpenDialog={setOpenDialog} openDialog={openDialog}></Login>
      </div>
    );
  } else {
    return (<Redirect to="/404"></Redirect>)
  }
}
const Editorr = (props) => {
  const classes = hover();
  const context = useContext(Context)
  var labels = []
  if (context.user !== null && context.user.rol === 10) {
    labels = Global.labels.concat(Global.labelsAdmin)
  } else {
    labels = Global.labels
  }
  const [imgs, setImgs] = useState([])
  const [content, setContent] = useState(props.cuerpo)
  const [titulo, setTitulo] = useState(props.titulo)
  const [steps, setSteps] = useState(0)
  const [categoria, setCategoria] = useState(props.categoria)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(props.labels);
  const [pendingValue, setPendingValue] = React.useState([]);
  const [openAlert, setOpenAlert] = React.useState(false)
  const [message, setMessage] = React.useState("")
  const [severity, setSeverity] = React.useState("")
  const [disabled, setDisabled] = useState(false)
  const theme = useTheme();
  const handleClick = (event) => {
    setPendingValue(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, reason) => {
    if (reason === 'toggleInput') {
      return;
    }
    setValue(pendingValue.sort((a, b) => labels.indexOf(a) - labels.indexOf(b)));
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'github-label' : undefined;
  const handleEditorChange = (content, editor) => {
    setContent(content)
  }
  const handleImages = (blobInfo, success, failure) => {
    const url = URL.createObjectURL(blobInfo.blob(), blobInfo.filename())
    var res = imgs
    res.push({ url, blob: blobInfo.blob(), filename: blobInfo.filename() })
    setImgs(res)
    success(url)
  }
  const reviseImages = (conten) => {
    var appear = imgs.filter(element => conten.includes(element.url))
    return appear
  }
  const saveEntrada = () => {
    const creador = context.user.nickname
    const tipo = value
    const idCreador = context.user._id
    const fecha = moment()
    const cuerpo = content
    var images = reviseImages(cuerpo)
    var formData = new FormData()
    images.forEach(x => {
      formData.append("file", x.blob, x.url.replace(/\//g, "-x-13-26-"))
    })
    axios.post(`${Global.url}foro/editarentrada`, { creador, tipo, idCreador, categoria, fecha, titulo, cuerpo, _id:props.data._id }, context.token).then(res => {
      if (res.data.status === "success") {
        setDisabled(false)
        if (images.length > 0) {
          axios.post(`${Global.url}foro/updateimageentrada/${res.data._id}`, formData, context.token).then(ress => {
            if (ress.data.status === "success") {
              context.setEntradas(ress.data.entradas, res.data.path)
              setDisabled(false)
            } else {
              setDisabled(false)
            }
          })
        } else {
          context.setEntradas(res.data.entradas, res.data.path)
          setDisabled(false)
        }
      } else {
        setDisabled(false)
      }
    })
  }
  const getCategoria = (array, categoria) => {
    return array.find(element => element.tag === categoria)
  }
  if (context.user === null) {
    return (<Login openDialog={true} setOpenDialog={() => { }} disableBackdropClick disableEscapeKeyDown></Login>)
  } else if (steps === 0) {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Datos principales</h4>
                <p className={classes.cardCategoryWhite}>Foro, título y etiquetas</p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      value={getCategoria(Global.sections, categoria).title}
                    />
                  </GridItem>
                  {context.user.rol >= 10 &&
                    <GridItem>
                      <div className={classes.root}>
                        <ButtonBase
                          disableRipple
                          className={classes.button}
                          aria-describedby={id}
                          onClick={handleClick}
                        >
                          <span>Etiquetas</span>
                          <SettingsIcon />
                        </ButtonBase>
                        {value.map((label) => (
                          <div
                            key={label.name}
                            className={classes.tag}
                            style={{
                              backgroundColor: label.color,
                              color: theme.palette.getContrastText(label.color),
                            }}
                          >
                            {label.name}
                          </div>
                        ))}
                      </div>
                      <Popper
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        placement="bottom-start"
                        className={classes.popper}
                      >
                        <div className={classes.header}>Etiquetas aplicadas a la entrada</div>
                        <Autocomplete
                          open
                          onClose={handleClose}
                          multiple
                          classes={{
                            paper: classes.paper,
                            option: classes.option,
                            popperDisablePortal: classes.popperDisablePortal,
                          }}
                          value={pendingValue}
                          onChange={(event, newValue) => {
                            setPendingValue(newValue);
                          }}
                          disableCloseOnSelect
                          disablePortal
                          renderTags={() => null}
                          noOptionsText="Sin etiquetas"
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <DoneIcon
                                className={classes.iconSelected}
                                style={{ visibility: selected ? 'visible' : 'hidden' }}
                              />
                              <span className={classes.color} style={{ backgroundColor: option.color }} />
                              <div className={classes.text}>
                                {option.name}
                                <br />
                                {option.description}
                              </div>
                              <CloseIcon
                                className={classes.close}
                                style={{ visibility: selected ? 'visible' : 'hidden' }}
                              />
                            </React.Fragment>
                          )}
                          options={[...labels].sort((a, b) => {
                            // Display the selected labels first.
                            let ai = value.indexOf(a);
                            ai = ai === -1 ? value.length + labels.indexOf(a) : ai;
                            let bi = value.indexOf(b);
                            bi = bi === -1 ? value.length + labels.indexOf(b) : bi;
                            return ai - bi;
                          })}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              ref={params.InputProps.ref}
                              inputProps={params.inputProps}
                              autoFocus
                              className={classes.inputBase}
                            />
                          )}
                        />
                      </Popper>
                    </GridItem>
                  }
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      label="Titulo"
                      value={titulo}
                      onChange={(e) => {
                        var res = e.target.value
                        setTitulo(res.substr(0, 51))
                      }}
                      helperText={`${titulo.length}/50${titulo.length > 50 ? " El título debe ser una breve descripción, luego podrás explicarte." : ""}`}
                      error={titulo.length > 50}
                      variant="standard"
                      style={{ margin: "10px 0" }}
                      fullWidth
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" onClick={(e) => {
                  var res = []
                  if (value.length === 0) res.push("añade al menos una etiqueta(Lo recomendado es una de 'Tipo')")
                  if (categoria === "") res.push("selecciona un foro donde escribir tu entrada")
                  if (titulo.length <= 3) res.push("el título es demasiado corto")
                  if (value.filter(element => element.name.includes("Tipo:")).length > 1) res.push("solo puedes poner una etiqueta 'Tipo'")
                  if (value.filter(element => element.name.includes("Tipo:")).length < 1) res.push("debes poner una etiqueta 'Tipo'")
                  if (value.filter(element => element.name.includes("Estado:")).length > 1) res.push("solo puedes poner una etiqueta 'Estado'")
                  if (value.filter(element => element.name.includes("Prioridad:")).length > 1) res.push("solo puedes poner una etiqueta 'Prioridad'")
                  if (res.length === 0) {
                    setSteps(1)
                  } else {
                    setMessage(res.join(", ").substr(0, 1).toUpperCase() + res.join(", ").substr(1) + ".")
                    setSeverity("error")
                    setOpenAlert(true)
                  }
                }}>Siguiente</Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <Alert open={openAlert} setOpen={(e) => setOpenAlert(e)} message={message} severity={severity}></Alert>
      </>
    )
  } else if (steps === 1) {
    return (
      <>
        <Button color="primary" onClick={() => { saveEntrada(); setDisabled(true) }} disabled={disabled}>Guardar</Button>
        <Editor
          apiKey={process.env.REACT_APP_TINY_API_KEY}
          initialValue="<p>Tu mensaje aquí</p>"
          value={content}
          init={{
            language: "es",
            height: 1000,
            selector: 'textarea',
            plugins: [
              'paste searchreplace autolink autosave directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking preview anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
            ],
            imagetools_cors_hosts: ['picsum.photos'],
            menubar: 'edit insert format tools table',
            toolbar: ['restoredraft undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | forecolor backcolor removeformat | outdent indent | numlist bullist | charmap emoticons | fullscreen preview save print | insertfile image media link anchor'],
            /* we override default upload handler to simulate successful upload*/
            images_upload_handler: handleImages,
            autosave_interval: '10s',
            autosave_ask_before_unload: false,
            autosave_prefix: 'tinymce-autosave-' + context.user._id,
          }}
          onEditorChange={handleEditorChange}
        />
      </>
    );
  }
}
