/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Gestiona from 'assets/img/gestiona.png'
import NGH from 'assets/img/e.png'
import Person from "@material-ui/icons/Person";
import AddIcon from '@material-ui/icons/Add'
import HomeIcon from '@material-ui/icons/Home';
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard";
import UserProfile from "views/UserProfile/UserProfile.js";
import Global from 'Global';
// core components/views for RTL layout

const dashboardRoutes = [
  {
    path: "/",
    name: "Inicio",
    icon: HomeIcon,
    component: DashboardPage,
  },
  ...Global.sections.map(section => ({
    path: `/foros/${section.tag}`,
    name: section.sortTitle || section.title,
    icon: section.asset,
    component: DashboardPage,
  })),
  {
    path: "/mi-perfil",
    name: "Mi perfil",
    icon: Person,
    component: UserProfile,
  },
  /* {
    path: "/foros/new",
    name: "Sugerir nueva categoría",
    icon: AddIcon,
    component: DashboardPage,
  }, */
];

export default dashboardRoutes;

