import React, {Component} from 'react';
import Context from './Context';
import axios from 'axios'
import Global from 'Global'
import moment from 'moment'
class Provider extends Component {
    state = {
        user: null,
        token: null,
        entradas: [],
        misEntradas: [],
        load:false,
    };
    privatee = (token) => {
        axios.get(Global.url + "foro/private", {
            headers: {
                'Authorization': token
            }
        })
            .then(res => {
                if (res.data.status === "error") {
                    this.deleteData()
                    this.setState({ open: true, message: res.data.message, severity: res.data.status, user: null })
                } else {
                    sessionStorage.setItem("UserToken", token)
                    this.setState({
                        user: res.data.decoded.user,
                        token: { headers: { 'Authorization': token } },
                        misEntradas: res.data.misEntradas
                    })
                }
            })
    }
    setOpen = (e) => {
        this.setState({ open: e })
    }
    setData = (e) => {
        this.setState(e)
    }
    deleteData = () => {
        sessionStorage.removeItem("UserToken")
        sessionStorage.removeItem("User")
        sessionStorage.removeItem("Entidad")
    }
    componentDidMount() {
        var ss = sessionStorage.getItem("UserToken")
        if (ss !== null) {
            this.privatee(ss)
        }
        var now = moment()
        document.addEventListener("mousemove", () => {
            now = moment()
        })
        document.addEventListener("keypress", () => {
            now = moment()
        })
        setInterval(() => {
            if (moment().diff(now, "minute", true) > 30) {
                if (this.state.token !== null) this.signOut()
            }
        }, 1000)
        axios.get(`${Global.url}foro/getentradas`).then(res=>{
            this.setState({entradas:res.data.entradas},()=>{this.setState({load:true})})
        })
    }

    signOut = () => {
        this.deleteData()
        this.setState({
            user: null,
            token: null,
            open: false,
            message: "",
            severity: "",
        })
    }

    render() {
        return (
            <Context.Provider
                value={{
                    user: this.state.user,
                    token:this.state.token,
                    entradas:this.state.entradas,
                    load:this.state.load,
                    misEntradas: this.state.misEntradas,
                    setUser: user => {
                        this.setState({
                            user
                        });
                    },
                    setAyun: ayun => {
                        this.setState({
                            ayun
                        });
                    },
                    setToken: token => {
                        this.setState({
                            token
                        });
                    },
                    setEntradas: (entradas,path) => {
                        this.setState({
                            entradas
                        });
                        if(path) window.location.href = path
                    },
                    private:this.privatee,
                    signOut:this.signOut,
                    
                }}
            >
                {this.props.children}
            </Context.Provider>
        );
    }
}

export default Provider