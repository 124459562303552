
import Gestiona from 'assets/img/gestiona.png'
import NGH from 'assets/img/e.png'

var Global = {
  url: process.env.NODE_ENV === 'development' ? 'http://192.168.0.253:3400/api/' : 'https://api.elocal.es/api/',
  sections: [{
    tag: 'nominas-grupoh',
    title: 'Nóminas GrupoH',
    color: 'info',
    asset: NGH
  }, {
    tag: 'nominas-grupoh-v6',
    title: 'Nóminas GrupoH (versión Beta)',
    sortTitle: 'Nóminas GrupoH vers. β',
    color: 'info',
    asset: NGH
  }, {
    tag: 'gestiona',
    title: 'Gestiona',
    color: 'warning',
    asset: Gestiona
  }
  ],
  labels: [
    {
      name: 'Tipo: Error',
      color: '#d73a4a',
      description: "Algo no funciona",
    },
    {
      name: 'Tipo: Pregunta',
      color: '#d876e3',
      description: 'Se solicita más información',
    },
    {
      name: 'Tipo: Discusión',
      color: '#d4c5f9',
      description: '',
    },
    {
      name: 'Tipo: Documentación',
      color: '#006b75',
      description: '',
    },
    {
      name: 'Tipo: Solicitud de función',
      color: '#fbca04',
      description: 'Proponer nuevas funciones para la aplicación',
    },
    {
      name: 'Tipo: Sugerir cambio',
      color: '#7057ff',
      description: 'Para mejorar el funcionamiento de la aplicación',
    },
    {
      name: 'Prioridad: Crítica',
      color: '#b60205',
      description: '',
    },
    {
      name: 'Prioridad: Alta',
      color: '#d93f0b',
      description: '',
    },
    {
      name: 'Prioridad: Baja',
      color: '#0e8a16',
      description: '',
    },
    {
      name: 'Prioridad: Media',
      color: '#fbca04',
      description: '',
    },
  ],
  labelsAdmin: [
    {
      name: 'Estado: Cerrado',
      color: '#215cea',
      description: '',
    }, {
      name: 'Estado: Resuelto',
      color: '#9dff00',
      description: '',
    },
  ],
  validateEmail: (email) => {
    var emailexpresion = /^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i
    if (emailexpresion.test(email) === true) {
      if (/^[A-Za-z0-9@.]+$/.test(email)) {
        return { "status": false, "message": "" }
      } else {
        return { "status": true, "message": "El email no puede contener caracteres especiales, excepto '@' y '.'" }
      }
    } else {
      return { "status": true, "message": "Email erróneo, formato no válido" }
    }
  },
  validatePass: (passOne, passTwo) => {
    if (passOne === passTwo) {
      if (passOne.length >= 8) {
        var mayuscula = false;
        var minuscula = false;
        var numero = false;
        var caracter_raro = false;
        for (var i = 0; i < passOne.length; i++) {
          if (passOne.charCodeAt(i) >= 65 && passOne.charCodeAt(i) <= 90) {
            mayuscula = true;
          }
          else if (passOne.charCodeAt(i) >= 97 && passOne.charCodeAt(i) <= 122) {
            minuscula = true;
          }
          else if (passOne.charCodeAt(i) >= 48 && passOne.charCodeAt(i) <= 57) {
            numero = true;
          }
          else {
            caracter_raro = true;
          }
        }
        if (mayuscula === true && minuscula === true && caracter_raro === true && numero === true) {
          return { status: false, message: "" }
        } else {
          return { status: true, message: "La contraseña debe tener al menos una mayúscula, minúscula, número y carácter especial." }
        }
      } else {
        return { status: true, message: "La contraseña debe tener ocho caractéres como mínimo." }
      }
    } else {
      return { status: true, message: "Las contraseñas no coinciden." }
    }
  }
};

export default Global;