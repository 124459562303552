import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Login from 'components/Login'
import styless from "assets/jss/material-kit-react/customCheckboxRadioSwitch.js";
import Alert from 'components/Alert'

import Global from 'Global'
import Context from 'components/Context'
import axios from 'axios'
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};
const useStyless = makeStyles(styless);
const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();
  const classess = useStyless();
  const context = React.useContext(Context)
  const [user, setUser] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [message, setMessage] = React.useState("")
  const [severity, setSeverity] = React.useState("")
  const updateProfile = () => {
    if (user.nombre && user.apellidos && /^[A-Za-z0-9]+$/.test(user.nickname) && !Global.validateEmail(user.email).status && ((user.password.length === 0 && user.passwordTwo.length === 0 )|| !Global.validatePass(user.password, user.passwordTwo).status)) {
      axios.post(`${Global.url}foro/updateprofile`,{email:user.email,password:user.password?user.password:undefined,nombre:user.nombre,apellidos:user.apellidos,nickname:user.nickname},context.token).then(res => {
          if (res.data.status === "success") {
              sessionStorage.setItem("UserToken", "H " + res.data.token)
              context.private("H " + res.data.token)
              setSeverity("success")
              setMessage(res.data.message)
              setOpen(true)
              setUser(prev=>({...prev,password:"",passwordTwo:""}))
          }else{
              if(res.data.err !== undefined){
                  const msg = []
                  for(var err in res.data.err){
                      msg.push(res.data.err[err].properties.message)
                  }
                  setMessage(msg.join(", ")+".")
              }else{
                  setMessage(res.data.message)
              }
              setSeverity("error")
              setOpen(true)
          }
      })
  } else {
      setSeverity("warning")
      setMessage("Rellene todos los campos, sin errores.")
      setOpen(true)
  }
  }
  React.useEffect(() => {
    if (user === null && context.user !== null) setUser({...context.user,password:"",passwordTwo:""})
  }, [context.user])
  console.log(user)
  return (
    <>
      {user !== null ?
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>{user.realName ? `${user.nombre} ${user.apellidos}` : user.nickname}</h4>
                  <p className={classes.cardCategoryWhite}>Nombre público</p>
                </CardHeader>
                <CardBody>
                  <GridContainer style={{ alignItems: "baseline" }}>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Nickname"
                        id="username"
                        formControlProps={{
                          fullWidth: true
                        }}
                        error={user.nickname.length > 0 && !/^[A-Za-z0-9]+$/.test(user.nickname)}
                        inputProps={{
                          value: user.nickname,
                          onChange:(e)=>{var res = e.target.value.replace(/\s/g, "");setUser(prev=>({...prev,nickname:res}))}
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Email"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        error={Global.validateEmail(user.email).status}
                        inputProps={{
                          value: user.email,
                          onChange:(e)=>{var res = e.target.value.replace(/\s/g,"");setUser(prev=>({...prev,email:res}))}
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onClick={() => setUser(prevUser => ({ ...prevUser, realName: !prevUser.realName }))}
                            checked={user.realName}
                            checkedIcon={<Check className={classess.checkedIcon} />}
                            icon={<Check className={classess.uncheckedIcon} />}
                            classes={{ checked: classess.checked }}
                          />
                        }
                        style={{ margin: 0 }}
                        classes={{ label: classess.label }}
                        label="Mostras nombre"
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Nombre"
                        id="first-name"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: user.nombre,
                          onChange:(e)=>{var res = e.target.value;setUser(prev=>({...prev,nombre:res}))}
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Apellidos"
                        id="last-name"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: user.apellidos,
                          onChange:(e)=>{var res = e.target.value;setUser(prev=>({...prev,apellidos:res}))}
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Cambiar contraseña"
                        id="new-password"
                        formControlProps={{
                          fullWidth: true
                        }}
                        success={(user.password.length > 0 && user.passwordTwo.length > 0) ?!Global.validatePass(user.password, user.passwordTwo).status:false}
                        error={(user.password.length > 0 && user.passwordTwo.length > 0) ?Global.validatePass(user.password, user.passwordTwo).status:false}
                        inputProps={{
                          type:"password",
                          value: user.password,
                          onChange:(e)=>{var res = e.target.value;setUser(prev=>({...prev,password:res}))}
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Repita contraseña"
                        id="repeat-password"
                        formControlProps={{
                          fullWidth: true
                        }}
                        success={(user.password.length > 0 && user.passwordTwo.length > 0) ?!Global.validatePass(user.password, user.passwordTwo).status:false}
                        error={(user.password.length > 0 && user.passwordTwo.length > 0) ?Global.validatePass(user.password, user.passwordTwo).status:false}
                        inputProps={{
                          type:"password",
                          value: user.passwordTwo,
                          onChange:(e)=>{var res = e.target.value;setUser(prev=>({...prev,passwordTwo:res}))}
                        }}
                      />
                    </GridItem>
                    <GridItem>
                      <span>{(user.password.length > 0 && user.passwordTwo.length > 0) ?Global.validatePass(user.password, user.passwordTwo).message:""}</span>
                      </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button color="primary" onClick={()=>updateProfile()}>Actualizar perfil</Button>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card profile>
                <CardBody profile style={{ marginTop: "0" }}>
                  <h4 className={classes.cardTitle}>Recibir mails</h4>
                  <div className={classes.description} style={{ display: "grid" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() =>
                            setUser(prevUser =>
                              ({
                                ...prevUser,
                                emailAlert: { 
                                  ...prevUser.emailAlert, 
                                  doc: !prevUser.emailAlert.doc }
                              }
                              ))}
                          checked={user.emailAlert.doc}
                          checkedIcon={<Check className={classess.checkedIcon} />}
                          icon={<Check className={classess.uncheckedIcon} />}
                          classes={{ checked: classess.checked }}
                        />
                      }
                      classes={{ label: classess.label }}
                      label="Documentación (Recomendado)"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() =>
                            setUser(prevUser =>
                              ({
                                ...prevUser,
                                emailAlert: { 
                                  ...prevUser.emailAlert, 
                                  pregunta: !prevUser.emailAlert.pregunta }
                              }
                              ))}
                          checked={user.emailAlert.pregunta}
                          checkedIcon={<Check className={classess.checkedIcon} />}
                          icon={<Check className={classess.uncheckedIcon} />}
                          classes={{ checked: classess.checked }}
                        />
                      }
                      classes={{ label: classess.label }}
                      label="Preguntas"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() =>
                            setUser(prevUser =>
                              ({
                                ...prevUser,
                                emailAlert: { 
                                  ...prevUser.emailAlert, 
                                  error: !prevUser.emailAlert.error }
                              }
                              ))}
                          checked={user.emailAlert.error}
                          checkedIcon={<Check className={classess.checkedIcon} />}
                          icon={<Check className={classess.uncheckedIcon} />}
                          classes={{ checked: classess.checked }}
                        />
                      }
                      classes={{ label: classess.label }}
                      label="Errores"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() =>
                            setUser(prevUser =>
                              ({
                                ...prevUser,
                                emailAlert: { 
                                  ...prevUser.emailAlert, 
                                  discusion: !prevUser.emailAlert.discusion }
                              }
                              ))}
                          checked={user.emailAlert.discusion}
                          checkedIcon={<Check className={classess.checkedIcon} />}
                          icon={<Check className={classess.uncheckedIcon} />}
                          classes={{ checked: classess.checked }}
                        />
                      }
                      classes={{ label: classess.label }}
                      label="Discusiones"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() =>
                            setUser(prevUser =>
                              ({
                                ...prevUser,
                                emailAlert: { 
                                  ...prevUser.emailAlert, 
                                  solicitud: !prevUser.emailAlert.solicitud }
                              }
                              ))}
                          checked={user.emailAlert.solicitud}
                          checkedIcon={<Check className={classess.checkedIcon} />}
                          icon={<Check className={classess.uncheckedIcon} />}
                          classes={{ checked: classess.checked }}
                        />
                      }
                      classes={{ label: classess.label }}
                      label="Solicitud de funciones"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() =>
                            setUser(prevUser =>
                              ({
                                ...prevUser,
                                emailAlert: { 
                                  ...prevUser.emailAlert, 
                                  sugerir: !prevUser.emailAlert.sugerir }
                              }
                              ))}
                          checked={user.emailAlert.sugerir}
                          checkedIcon={<Check className={classess.checkedIcon} />}
                          icon={<Check className={classess.uncheckedIcon} />}
                          classes={{ checked: classess.checked }}
                        />
                      }
                      classes={{ label: classess.label }}
                      label="Sugerir cambio"
                    />
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
                <Alert open={open} setOpen={(e) => setOpen(e)} message={message} severity={severity}></Alert>
        </div>
        : <Login openDialog={true} setOpenDialog={() => { }} disableBackdropClick disableEscapeKeyDown></Login>
      }
    </>
  );
}
