import React from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import logo from "assets/img/e.png";
import Provider from 'components/Provider'
//Rutas
import Editor from 'Editor'
import DashboardPage from "views/Dashboard/Dashboard";
import Entradas from 'Entradas'
import Entrada from 'Entrada'
import Profile from 'views/UserProfile/UserProfile'
import AvisoLegal from 'AvisoLegal'
let ps;

const switchRoutes = (
    <Switch>
        <Route
            exact
            path={"/"}
            component={DashboardPage}
        />
        <Route exact path="/editor/:categoria?" component={Editor}></Route>
        <Route exact path={"/foros"} component={()=><Redirect to='/' />}/>
        <Route exact path={"/foros/:categoria"} component={Entradas}/>
        <Route exact path={"/foros/:categoria/:titulo"} component={Entrada}/>
        <Route exact path={"/mi-perfil"} component={Profile}/>
        <Route exact path={"/aviso-legal"} component={AvisoLegal}/>
        <Route exact path="/404" component={()=><h1>404</h1>}/>
        <Route path="*" component={()=><Redirect to='/404' />}></Route>
    </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
    // styles
    const classes = useStyles();
    // ref to help us initialize PerfectScrollbar on windows devices
    const mainPanel = React.createRef();
    // states and functions
    const [color] = React.useState("blue");
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current, {
                suppressScrollX: true,
                suppressScrollY: false
            });
            document.body.style.overflow = "hidden";
        }
        window.addEventListener("resize", resizeFunction);
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
            window.removeEventListener("resize", resizeFunction);
        };
    }, [mainPanel]);
    return (
        <Provider>
            <BrowserRouter>
                <div className={classes.wrapper}>
                    <Sidebar
                        routes={routes}
                        logoText={"Foro | eLocal"}
                        logo={logo}
                        bgColor={color}
                        handleDrawerToggle={handleDrawerToggle}
                        open={mobileOpen}
                        color={color}
                        {...rest}
                    />
                    <div className={classes.mainPanel} ref={mainPanel}>
                        <Navbar
                            routes={routes}
                            handleDrawerToggle={handleDrawerToggle}
                            {...rest}
                        />
                        <div className={classes.content}>
                            <div className={classes.container}>{switchRoutes}</div>
                        </div>
                    </div>
                </div>
            </BrowserRouter>
        </Provider>
    );
}