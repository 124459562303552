import React, { useContext } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardActionArea from '@material-ui/core/CardActionArea';
import Global from '../../Global'

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Context from 'components/Context'
import moment from 'moment'
import { Link } from 'react-router-dom'
const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const context = useContext(Context)
  const [entradas, setEntradas] = React.useState(Global.sections.reduce((pre, cur) => {
    return { ...pre, [cur.tag]: [] }
  }, {}));
  React.useEffect(() => {
    const entradas = Global.sections.reduce((pre, cur) => {
      return { ...pre, [cur.tag]: context.entradas.filter(element => element.categoria === cur.tag) }
    }, {})
    setEntradas(entradas)
  }, [context])

  return (
    <div>
      <GridContainer>
        {Global.sections.map(element => (
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardActionArea component={Link} to={`/foros/${element.tag}`}>
                <CardHeader color={element.color} stats icon>
                  <CardIcon color="info">
                    <img src={element.asset} alt={element.title} height="40px"></img>
                  </CardIcon>
                  <p className={classes.cardCategory}>{element.title}</p>
                  <h3 className={classes.cardTitle}>
                    {entradas[element.tag].length} <small>entradas</small>
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <DateRange />
                    {entradas[element.tag].length === 0 ? "Sin entradas" : moment(entradas[element.tag].sort((a, b) => moment(b.fecha) - moment(a.fecha))[0].fecha).format("DD/MM/YYYY")}
                  </div>
                </CardFooter>
              </CardActionArea>
            </Card>
            <Card>
            <CardActionArea component={Link} to="/foros/nominas-grupoh">
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>Entradas {element.title}</h4>
              </CardHeader>
            </CardActionArea>
            <CardBody>
              <Table
                tableHeaderColor="info"
                tableHead={["Fecha", "Título", "Etiquetas"]}
                tableData={entradas[element.tag]}
              />
            </CardBody>
          </Card>
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
}
