import React, { useContext } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {Link} from 'react-router-dom'
import Gestiona from 'assets/img/gestiona.png'
import NGH from 'assets/img/e.png'

import { Redirect } from 'react-router-dom'
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Context from 'components/Context'
import Button from 'components/CustomButtons/Button'
import moment from 'moment';
import Global from './Global'

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const categoria = props.match.params.categoria
  const classes = useStyles();
  const context = useContext(Context)
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    setData(context.entradas.filter(element => element.categoria === categoria).sort((a,b)=>moment(b.fecha)-moment(a.fecha)))
  }, [categoria, context.entradas])
  const categoriaData = Global.sections.find(e => e.tag === categoria)
  if (categoriaData) {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <img src={categoriaData.asset} alt={categoriaData.title} height="40px" style={{ paddingRight: "10px" }}></img>
                <h3 style={{ color: "#777" }}>Entradas
                {' '+categoriaData.title}
                </h3>
              </CardHeader>
              <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", padding:"20px 30px 0"}}>
                <h3 className={classes.cardTitle}>
                  {data.length} <small>entradas</small>
                </h3>
                <Button color="white" component={Link} to={"/editor/"+categoria}><strong>Crear entrada</strong></Button>
              </div>
              <CardBody>
                <Table
                  tableHeaderColor="info"
                  tableHead={["Fecha", "Título","Etiquetas"]}
                  tableData={data}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  } else {
    return (<Redirect to="/404"></Redirect>)
  }
}
