import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Context from 'components/Context'

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import Login from 'components/Login'
import PersonOutline from '@material-ui/icons/PersonOutline';
import { Link } from 'react-router-dom'
import axios from "axios";
import Global from 'Global'

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const classes = useStyles();
  const context = React.useContext(Context)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const leido = (_id) => {
    axios.post(`${Global.url}foro/leido`,{_id},context.token).then(({data})=>{
      if(data.status === "success") context.setUser(data.user)
    })
  }
  return (
    <div>
      {/* <div className={classes.searchWrapper}>
        <CustomInput
          formControlProps={{
            className: classes.margin + " " + classes.search
          }}
          inputProps={{
            placeholder: "Search",
            inputProps: {
              "aria-label": "Search"
            }
          }}
        />
        <Button color="white" aria-label="edit" justIcon round>
          <Search />
        </Button>
      </div> */}
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          <Notifications className={classes.icons} />
          {context.user !== null && context.user.notificaciones.length > 0 && <span className={classes.notifications}>{context.user.notificaciones.length}</span>}
          <Hidden mdUp implementation="css">
            <p onClick={handleCloseNotification} className={classes.linkText}>
              Notificaciones
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    {context.user === null &&
                      <MenuItem
                        onClick={() => setOpenDialog(true)}
                        className={classes.dropdownItem}
                      >
                        Inicia sesión o regístrate
                    </MenuItem>
                    }
                    {context.user !== null &&
                      <>
                        {
                          context.user.notificaciones.length === 0 ?
                            <MenuItem
                              onClick={handleCloseNotification}
                              className={classes.dropdownItem}
                            >
                              Sin notificaciones
                    </MenuItem>
                            : context.user.notificaciones.map((x, i) => {
                              return (
                                <MenuItem
                                  component={Link}
                                  onClick={()=>{leido(x._id);handleCloseNotification();}}
                                  to={x.href}
                                  key={i}
                                  className={classes.dropdownItem}
                                >
                                  {x.cuerpo}
                                </MenuItem>

                              )
                            })
                        }
                      </>
                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={context.user ? handleClickProfile : () => setOpenDialog(true)}
          className={classes.buttonLink}
        >
          {context.user !== null
            ? <Person className={classes.icons} />
            : <PersonOutline className={classes.icons} />
          }

          <Hidden mdUp implementation="css">
            <p className={classes.linkText} component={Link} to="/mi-perfil">Perfil</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <Link
                       to="/mi-perfil">
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Perfil
                    </MenuItem>
                    </Link>
                    <Divider light />
                    <MenuItem
                      onClick={() => { handleCloseProfile(); context.signOut() }}
                      className={classes.dropdownItem}
                    >
                      Cerrar sesión
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      <Login setOpenDialog={setOpenDialog} openDialog={openDialog}></Login>
    </div >
  );
}
